.accordion {
    box-shadow: none;
    padding: 0 !important; /* To overwrite mui styles */
}

.accordion::before {
    content: none;
}

.accordionSummary {
    cursor: default !important; /* To overwrite mui styles */
}

.accordionSummary:global(.MuiAccordionSummary-root .MuiAccordionSummary-content) {
    position: relative;
}

.accordionDetails {
    padding: 8px 16px;
}

.subItem {
    padding: 8px 0px;
}

.titleWrapper {
    width: 100%;
    cursor: default;
}

.titleContainer {
    gap: 4px;
}

.accordionTitle {
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    position: relative;
    cursor: pointer;
}

.accordionTitleUnderline {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    opacity: 0.1;
    background: var(--color-primary);
}

.accordionTitle::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: var(--secondary);
    bottom: 0;
    transform: translateY(100%);
    left: 0;
}

.expandIconContainer {
    width: 24px;
    height: 24px;
    transition: all 0.3s;
    transform: rotate(-90deg);
    transform-origin: center;
    cursor: pointer;
}

.expandIconContainerOpen {
    transform: rotate(0deg);
}
