.container {
    gap: 4px;
    width: 100%;
    max-width: 240px;
    position: relative;
}

.phoneNumberInput:global(.MuiTelInput-TextField .MuiInputBase-root) {
    font-size: 14px;
    padding-left: 4px;
}

.phoneNumberInput:global(.MuiTelInput-TextField .MuiInputBase-root input) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 5px;
}

.phoneNumberInput:global(.MuiTelInput-TextField .MuiInputBase-root .MuiTelInput-IconButton) {
    padding: 4px;
}

.phoneNumberInput:global(.MuiTelInput-TextField .MuiInputBase-root .MuiTelInput-FlagImg) {
    width: 20px;
}
