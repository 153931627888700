.resetAllContainer {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 6px 16px;
}

.resetAllDivider {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #afafaf;
    display: block;
}

.resetAllButton {
    flex: none;
    align-self: center;
}

.columnFilteredIcon {
    align-items: center;
    display: flex;
    justify-content: center;
    background: var(--text-color-secondary);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    transition: all 0.3s;
}

.columnFilteredIcon:hover {
    background: #4c6586;
}
