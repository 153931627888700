.form {
    width: 100%;
    padding: 0;
    gap: 0;
}

.profileWrapper {
    display: flex;
    gap: 4px;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    padding: 8px;
    margin-bottom: 32px;
}

.avatarWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.userName {
    display: flex;
    gap: 8px;
}

.userName h6 {
    font-size: 24px;
}

.profileItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.profileTitleContainer {
    align-items: center;
}

.profileContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.accountInfoBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px;
    margin-bottom: 8px;
}

.accountInfo {
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid var(--color-handle-fill);
    background: var(--grey-350);
    border-radius: 6px 6px 0 0;
}

.profileButtonsContainer {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin-left: 16px;
}

.fieldsGrid {
    margin-bottom: 16px;
}

.profileButton {
    padding: 10px 32px;
    border: 1px solid;
    text-transform: none;
    font-weight: bold;
    background: var(--color-primary);
    color: var(--grey-300);
    border-radius: 6px;
}

.profileButton:hover {
    color: black;
}

.activeButton {
    background: var(--secondary);
    color: var(--white);
}

.changePasswordDialogButtons {
    margin-top: 16px;
}

.changePasswordDialogYes {
    background: var(--color-primary);
    color: var(--white);
}

.changePasswordDialogYes:hover {
    background: #12294585;
}

@media (min-width: 700px) {
    .profileContainer {
        width: unset;
    }
}

@media (min-width: 600px) {
    .avatar {
        justify-content: start;
    }
}
