.commodities {
    position: relative;
    gap: 20px;
    width: 100%;
}

.commoditiesHeader {
    width: 100%;
    flex-grow: 1;
}

.editModeField {
    width: fit-content;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #e7eaec;
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.subsectionTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 1rem;
    color: var(--color-primary);
}

.seizedCommoditiesList {
    overflow-x: auto;
    overflow-y: visible;
}

.addButton {
    position: absolute;
    top: 0;
    right: 0;
}
