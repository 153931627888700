.footerContainer {
    flex-wrap: wrap;
    height: auto;
    gap: 16px;
    background-color: var(--white);
}

.description {
    flex-wrap: wrap;
}

.organizationName {
    margin-top: 8px;
}

.footerContainer .link {
    font-weight: 700;
    color: #000000;
}

.descriptionDivider {
    display: none;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.footerContainer .link {
    margin-left: 0;
}

@media (min-width: 450px) {
    .links {
        flex-direction: row;
    }

    .footerContainer .link {
        margin-left: 32px;
    }

    .footerContainer .link:first-child {
        margin-left: 0;
    }
}

@media (min-width: 500px) {
    .description {
        flex-wrap: nowrap;
    }

    .descriptionDivider {
        display: unset;
    }
}

@media (min-width: 810px) {
    .footerContainer {
        flex-wrap: nowrap;
    }
}
