.radioGroup {
    flex-wrap: nowrap;
}

.radioGroup:global(.MuiFormGroup-root .MuiFormControlLabel-root) {
    white-space: nowrap;
}

.radioGroup:global(.MuiFormGroup-root .MuiButtonBase-root) {
    padding: 5px;
    margin: 0 4px;
}
