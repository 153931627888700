.header {
    border-bottom: 1px solid var(--grey-300);
}

.body {
    margin-bottom: 32px;
}

.question {
    font-weight: bold;
    color: var(--text-color-primary);
}

.closeIcon {
    position: absolute;
    right: 8px;
    top: 8px;
}

.footerButtons {
    display: flex;
    gap: 8px;
}

.footer {
    display: flex;
    justify-content: flex-end;
}
