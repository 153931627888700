.loaderMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(18, 41, 69, 0.2);
    z-index: 1;
}

button.closeIcon {
    position: absolute;
    right: 8px;
    top: 8px;
}
