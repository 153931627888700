.courtCaseDialog:global(.MuiDialog-root .MuiPaper-root) {
    max-width: 1400px;
}

.defendantsItem {
    margin: 0 14px;
}

.itemElements {
    margin: 0 14px;
}

.wrapper {
    border-radius: 8px;
    border: 1px solid var(--grey-300);
    margin-bottom: 8px;
}

.wrapper:last-child {
    margin-bottom: 0;
}

.item {
    margin: 4px;
    border-radius: 8px;
    border: 1px solid var(--grey-300);
    overflow: hidden;
}

.itemFooter {
    margin: 4px;
    border-radius: 8px;
    border: 1px solid var(--grey-300);
}

.itemAdditionalInfo {
    display: flex;
    margin: 4px;
    gap: 4px;
    align-items: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.itemAdditionalInfo:last-child {
    border-bottom: none;
}

.itemAdditionalInfoTitle {
    flex: none;
}

.itemAdditionalInfoTitle:global(.MuiListItemText-root .MuiTypography-root) {
    line-height: 1;
    padding-left: 4px;
}

.itemTitleBlock {
    display: flex;
    background: var(--light-blue-100);
    flex-wrap: nowrap;
}

.boxHeader {
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 16px;
}

.boxHeaderUnderLineInnerContainer {
    position: absolute;
    display: flex;
    bottom: 0;
    width: 100%;
    align-items: end;
}

.boxHeaderTextUnderline {
    line-height: 3px;
    overflow: hidden;
    background-color: var(--secondary);
    color: var(--secondary);
    flex: none;
    width: fit-content;
}

.boxHeaderUnderline {
    flex: 1;
    width: 100%;
    height: 1px;
    background: var(--grey-300);
}

.title,
.title {
    color: var(--dark-blue-800);
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.subtitle span {
    font-size: 0.75rem;
    color: var(--dark-blue-800);
    padding-left: 16px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
}

.defendantNameBlock {
    display: flex;
    align-items: center;
}

.defendantName span {
    background: var(--text-grey-100);
    width: fit-content;
    margin-left: 16px;
    padding: 4px;
    line-height: 1;
    border-radius: 4px;
}

div.tableHeaderContainer {
    margin: 0 12px;
}

div.tableHeaderContainer,
div.row {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

div.hearingTableHeaderContainer {
    border-bottom: none;
}

div.rowItemRow {
    border-bottom: none;
}

.cellItem {
    display: flex;
    flex-direction: column;
}

.rowsBlockWrapper {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.rowsBlockWrapper:last-child {
    border-bottom: none;
}

div.row:last-child {
    border-bottom: none;
}

p.tableText {
    color: var(--table-text-color-secondary);
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.5rem;
    display: table-cell;
    vertical-align: inherit;
    text-align: left;
    padding: 8px 0 8px;
}

.defendantItemText:global(.MuiListItemText-root .MuiTypography-root) {
    line-height: 1;
    padding-bottom: 8px;
}

p.tableTextValue {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--black);
}

p.defendantTableTextValue {
    padding: 0;
    line-height: 1.2rem;
    padding-bottom: 8px;
}

.defendantsHeader {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 8px;
}

.defendantsTableText {
    font-weight: 900;
    /* font-size: 0.9rem; */
    font-size: 0.75rem;
}

.defendantsHeaderLine {
    flex: 1;
    height: 1px;
    background: rgba(224, 224, 224, 1);
}

.defendantsFooter {
    margin: 4px 14px 4px 4px;
}

p.defendantsFooterTitle {
    padding: 0;
}

.defendantsFooterBlock {
    border-top: 1px solid rgba(224, 224, 224, 1);
    padding: 8px 2px;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 8px;
}

.hearingsNotes {
    align-items: flex-start;
}

.additionalText {
    font-size: 0.75rem;
}

.hearingsNotesText {
    word-break: break-all;
    font-size: 0.75rem;
}

.hearingsAdditionalInfoTitle {
    display: inline-block;
    margin-right: 4px;
}

.defendantInfoContainer {
    display: flex;
    flex-direction: column;
    max-width: 100px;
    height: fit-content;
}

.defendantInfo:global(.MuiListItemText-root .MuiTypography-root) {
    margin-top: 10px;
    padding: 4px;
    line-height: 1;
    background: #ff9797;
    width: fit-content;
    border-radius: 4px;
}

.hearingsEndContentSubtitle:global(.MuiListItemText-root .MuiTypography-root) {
    line-height: 1;
    padding: 4px;
    margin-top: 10px;
    background: #ff9797;
    width: fit-content;
    border-radius: 4px;
}

.hearingsTitleEndContent {
    width: fit-content;
    flex: none;
    margin: 0 16px;
}

.caseDetailsContainer:global(.MuiGrid-container .MuiGrid-item) {
    padding-top: 0;
}
