.resetPasswordForm {
    height: fit-content;
    gap: 35px;
    min-width: 280px;
    position: relative;
}

.snackbar {
    max-width: 500px;
}

.closeButton {
    position: absolute;
    top: 8px;
    right: 10px;
}
