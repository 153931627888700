.textField {
    max-width: 360px;
    width: 100%;
}

.textField:global(.MuiFormControl-root .MuiInputBase-input) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 0;
}

.textField:global(.MuiFormControl-root .MuiInputBase-root) {
    padding: 5px;
    gap: 10px;
}

.errorMessage {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: var(--status-cancel);
}

.error:global(.MuiFormControl-root .MuiOutlinedInput-notchedOutline) {
    border-color: var(--status-cancel);
}

.error:global(.MuiFormControl-root .MuiOutlinedInput-root) {
    padding-right: 8px !important; /* To shadow mui styles */
}

.errorIcon {
    fill: var(--status-cancel);
}
