.wrapper {
    gap: 20px;
}

.defendantHeader {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.nameContainerWrapper {
    gap: 20px;
}

.nameInnerWrapper {
    gap: 20px;
}

.nameContainer {
    gap: 4px;
}

.name {
    flex-direction: row;
    align-items: center;
    width: fit-content;
}

.formBlock {
    gap: 20px;
    flex-wrap: wrap;
}

.notesWrapper {
    width: 100%;
}

.notesFormItem {
    width: 100%;
}

.noText {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: var(--color-primary);
    text-align: center;
}

.subsectionTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 1rem;
    color: var(--color-primary);
}

.disabledFilter {
    filter: opacity(0.5);
}

@media (min-width: 768px) {
    .defendantHeader {
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }
}

@media (min-width: 992px) {
    .defendantHeader {
        grid-template-columns: minmax(0, 2fr) repeat(3, minmax(0, 1.5fr));
    }
}
