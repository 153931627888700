.container {
    gap: 4px;
    width: 100%;
    position: relative;
}

.label {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.errorLabel {
    color: var(--status-cancel);
}

.value {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
