.text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.showMore {
    cursor: pointer;
    color: var(--dark-blue-100);
    text-decoration: underline;
}
