.trigger {
    gap: 8px;
}

.triggerLabel {
    cursor: pointer;
}

.infoIcon {
    width: 16px;
    height: 16px;
}
