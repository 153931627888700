.wrapper {
    gap: 12px;
}

.tableHeaderTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: var(--color-primary);
}

.tableHeaderToolbar {
    gap: 4px;
}

.usersTable {
    height: 500px;
}

.toolbarIcon {
    fill: var(--grey-250);
    width: 22px;
    height: 22px;
}
