.searchInputContainer {
    display: flex;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    width: 240px;
}

.searchContainer {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    transform: translate(100%, -50%);
    transition: all 0.3s;
    background: white;
    z-index: 1;
    max-width: 0;
    overflow: hidden;
}

.searchContainerOpen {
    transform: translate(0%, -50%);
    max-width: 100%;
}

.searchIcon {
    fill: var(--grey-250);
    width: 22px;
    height: 22px;
}

.searchTextField {
    width: 100%;
}

.searchTextField:global(.MuiTextField-root .MuiInputBase-input) {
    padding: 8px;
    font-size: 14px;
}

.searchTextField:global(.MuiTextField-root .MuiInputBase-root) {
    padding-right: 4px;
}
