.tableContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 24px;
    gap: 16px;
}

.title {
    color: var(--color-primary);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.search {
    width: 100%;
    max-width: 280px;
}

.search:global(.MuiTextField-root .MuiInputBase-input) {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.search:global(.MuiTextField-root .MuiInputBase-root) {
    padding: 10px;
    display: flex;
    gap: 8px;
}

.searchIcon {
    fill: var(--color-primary);
    width: 16px;
    height: 16px;
}

.status {
    color: var(--color-primary);
    gap: 4px;
    overflow: hidden;
}

.statusIcon {
    width: 20px;
    height: 20px;
}

.avatarGroup div.userAvatar {
    background-color: var(--color-primary);
    border: none;
    width: 24px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}
