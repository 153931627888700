.tableHeader {
    position: relative;
    margin-bottom: 25px;
}

.label {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.tableHeader:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    z-index: 0;
}

.tableTabs:global(.MuiTabs-root .MuiTabs-indicator) {
    z-index: 1;
}

.searchIcon {
    fill: var(--grey-200);
}

.loaderWrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.dividerBlock {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 0;
}

.dividerBlockLabel {
    flex: none;
    line-height: 1;
}

.dividerLine {
    flex: 1;
    height: 1px;
    background: var(--grey-300);
    margin-top: 4px;
}

.closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
}

.closeIcon {
    width: 24px;
    height: 24px;
    fill: var(--color-primary);
}

.nameContainer {
    display: flex;
    align-items: flex-end;
    gap: 8px;
}
