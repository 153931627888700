.tableBox {
    width: 100%;
    position: relative;
    margin-top: 24px;
}

.loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(125, 125, 125, 0.3);
}
