.trigger {
    width: 100%;
}

.triggerLabel {
    cursor: pointer;
}

.charges {
    width: 100%;
}

.chargesDropdown {
    width: 100%;
}

.titleContainer {
    width: 100%;
}
