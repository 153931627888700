.dialog {
    position: relative;
}

.loader {
    position: absolute;
}

.checkbox {
    padding: 0;
    padding-right: 0 !important; /* This is required to shade mui styles */
    flex: none;
    border-radius: 50%;
}

.listText {
    margin-left: 8px;
}

.listText:global(.MuiListItemText-root .MuiTypography-root) {
    text-overflow: ellipsis;
    overflow: hidden;
}

.footer {
    display: flex;
    justify-content: flex-end;
}

.deleteDashboardTitleContainer {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 8px;
}

.deleteDashboardTitle {
    font-weight: 300;
    color: var(--text-color-primary);
}
