.deleteButton {
    width: 30px;
    height: 30px;
}

.deleteIcon {
    width: 16px;
    height: 16px;
}

.gray {
    background-color: var(--button-gray);
    color: var(--color-primary);
}
