.container {
    position: relative;
    gap: 40px;
    padding: 24px;
    justify-content: flex-start;
}

.sidebar {
    gap: 12px;
    max-width: 240px;
    position: sticky;
    top: 0;
    max-height: calc(100vh - 88px);
}

.content {
    width: 100%;
}

.contentDetails {
    padding: 0 16px;
}

.contentContainer {
    width: 100%;
    min-width: 0;
}

.loader {
    min-height: calc(100vh - var(--header-height));
}
