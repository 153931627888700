.linksContainer {
    gap: 24px;
    padding: 16px 24px 16px 24px;
    box-shadow: 0 4px 8px 0 #00000040;
    background: var(--background-surface-low);
    border-radius: 6px;
    min-width: 200px;
}

.link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    position: relative;
    width: fit-content;
    cursor: pointer;
}

.activeLink::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    background: var(--secondary);
    overflow: hidden;
    width: 100%;
    height: 3px;
    max-width: 0;
    animation-name: activeLinkStartAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.linkText {
    color: var(--dark-blue-800);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

@keyframes activeLinkStartAnimation {
    from {
        max-width: 0;
    }

    to {
        max-width: 200px;
    }
}
