.addButton {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    background-color: var(--button-gray);
    color: var(--color-primary);
    padding: 10px;
    border-radius: 4px;
    text-transform: none;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
    right: 0;
    bottom: -4px;
    outline: 8px solid var(--white);
}
