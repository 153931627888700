.advancedFilterBlock {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.advancedFilterIcon {
    width: 22px;
    height: 22px;
    fill: var(--color-primary);
}

.advancedFilterText {
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    text-transform: uppercase;
    color: var(--color-primary);
}

.tooltipBlock {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dot {
    width: 8px;
    height: 8px;
    background: var(--color-primary);
    border-radius: 50%;
    margin-left: 8px;
}
