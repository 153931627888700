.container {
    width: 100%;
}

.dropdownButton {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    gap: 8px;
    padding: 0;
    min-width: 0;
    width: 100%;
}

.titleContainer {
    display: flex;
    gap: 2px;
    align-items: center;
    min-width: 0;
}

.titleText {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--black-800);
}

.permissionName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdownArrow {
    fill: var(--black-800);
}

.permissions {
    max-height: 280px;
    width: 280px;
    overflow-x: auto;
    padding: 8px 0;
}

.permissionObject {
    display: flex;
    gap: 4px;
    width: 100%;
    padding: 12px;
    margin: 0;
}

.permissionObject:global(.MuiFormControlLabel-root .MuiTypography-root) {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    color: var(--color-primary);
}

.permissionObject:global(.MuiFormControlLabel-root .MuiCheckbox-root) {
    padding: 0;
}

.permissionObject:hover {
    background: var(--light-blue-50);
}

.permissionsList {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding-inline-start: 16px;
    margin: 4px 0;
}

.permissionsListItem {
    margin: 8px 0;
}
