.editProfileTitleContainer {
    align-items: flex-start;
}

.passwordGrid {
    width: 100%;
}

.passwordField {
    width: 100%;
}

.input {
    border: 1px solid var(--color-primary);
}

.resetPassword {
    color: var(--secondary);
    white-space: nowrap;
    cursor: pointer;
    margin-left: 16px;
    padding: 0;
}

.editProfileItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.typographySubTitleBold {
    font-weight: 600;
}

.changePasswordTitle {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid var(--color-divider);
}

.changePasswordTitleText {
    padding: 0;
}

.changePassword {
    color: var(--white);
    padding: 8px;
    border-radius: 4px;
    text-transform: none;
    font-size: 0.8rem;
}

.changePassword:hover {
    background: var(--color-handle-fill);
}

.changePasswordDialogButtons {
    margin-top: 16px;
    display: flex;
    justify-content: end;
    gap: 8px;
}

.changePasswordDialogYes {
    background: var(--secondary);
    color: var(--white);
}

.changePasswordDialogYes:hover {
    background: #12294585;
}

.resetPasswordSentIcon {
    width: 128px;
    height: 128px;
    padding: 32px;
    border-radius: 50%;
    background: #f7515133;
    fill: var(--secondary);
    margin: 0 auto;
    display: block;
    margin-bottom: 24px;
}

.resetPasswordSentText {
    font-weight: 600;
}

.loaderWrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.editProfileButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
