.officers {
    position: relative;
    gap: 20px;
    width: 100%;
}

.officersHeader {
    width: 100%;
    flex-grow: 1;
}

.subsectionTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 1rem;
    color: var(--color-primary);
}

.addButton {
    position: absolute;
    top: 0;
    right: 0;
}
