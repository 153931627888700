.fieldContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.editableFieldLabel {
    font-size: 14px;
    color: #536378;
}

.readonlyFieldLabel {
    font-size: 14px;
    color: #7a8796;
}

.readonlyField {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    padding: 4px 0;
    background: var(--grey-100);
}

.editableField {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    background: var(--grey-350);
    padding: 4px 0;
}

.editableField:global(.MuiFormControl-root fieldset),
.readonlyField:global(.MuiFormControl-root fieldset) {
    border-color: transparent !important;
}

.editableField:global(.MuiFormControl-root input) {
    -webkit-text-fill-color: var(--text-color-primary);
}

.readonlyField:global(.MuiFormControl-root input) {
    -webkit-text-fill-color: var(--text-grey-100);
}
