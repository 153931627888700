.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.dialogTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    color: var(--table-text-color-secondary);
    padding: 0;
    padding-top: 8px;
}

.deleteImageContainer {
    position: relative;
}

.dialogContent {
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: center;
}

.dialogContent:global(.MuiDialogContent-root .MuiTypography-root) {
    color: var(--table-text-color-secondary);
}

.deleteButton {
    background: var(--secondary);
    font-weight: bold;
    color: var(--white);
    border-radius: 4px;
    padding: 8px 35px;
    margin: 0 auto;
    display: block;
    width: 90%;
}

.deleteButton:hover {
    background: var(--delete-button-hover-background);
}

.documentIcon {
    display: block;
    width: 100px;
    height: 100px;
    fill: var(--text-color-primary);
}

.highlightOffIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    border-radius: 50%;
    fill: var(--secondary);
    width: 34px;
    height: 34px;
}
