.btn {
    display: flex;
    gap: 8px;
    background: var(--secondary);
    color: var(--white);
    padding: 12px 16px;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    line-height: 1;
}

.btn:hover {
    background: var(--delete-button-hover-background);
}

.btnIcon {
    width: 16px;
    height: 16px;
}
