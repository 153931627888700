.inputContainer {
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 240px;
    border-radius: 4px;
    position: relative;
}

.inputContainer:hover {
    border-color: rgba(0, 0, 0, 0.87);
}

.inputContainerFieldset {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 2px solid transparent;
    border-radius: 4px;
    transform: translate(-1px, -1px);
}

.inputContainerFocus {
    border-color: #525f72;
    border-width: 2px;
}

.select {
    padding: 0 !important;
    min-width: 64px;
}

.select:global(.MuiInputBase-root .MuiSelect-select) {
    min-height: 0;
    padding: 0 8px;
}

.select:global(.MuiInputBase-root .MuiSvgIcon-root) {
    right: 0;
}

.select:global(.MuiInputBase-root .MuiOutlinedInput-notchedOutline),
.textField:global(.MuiTextField-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline) {
    border: none;
}
