div.loader {
    position: absolute;
}

.dialogTitleBlock {
    display: flex;
    gap: 8px;
    align-items: center;
}

.titleIcon {
    fill: var(--color-primary);
    width: 28px;
    height: 28px;
}

.title {
    font-weight: 300;
}

.titleWrapper {
    border-bottom: 1px solid var(--text-grey-100);
    margin-bottom: 10px;
}

.closeIcon {
    position: absolute;
    right: 12px;
    top: 12px;
}

.filtersHeader {
    display: flex;
    gap: 10px;
    padding: 8px;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-300);
}

.filtersHeaderItem {
    width: 160px;
}

.footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding: 8px;
    border-top: 1px solid var(--grey-300);
}

.submitButton {
    width: 100%;
    background: var(--grey-300);
}

.filtersHeaderSpace {
    width: 40px;
    flex: none;
}

.noFiltersSelected {
    padding: 8px 0;
    padding-bottom: 16px;
}

.advancedFilterTools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.advancedFilterTitleBlock {
    display: flex;
    align-items: center;
    gap: 8px;
}
