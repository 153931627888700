.line {
    width: 100%;
    height: 1px;
    background-color: var(--color-primary);
    opacity: 0.1;
}

.boldLine {
    height: 2px;
    opacity: 0.5;
    background-color: #12294580;
}
