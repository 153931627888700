.title {
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    color: var(--color-primary);
}

.savedStatus {
    background-color: var(--greenish-blue);
    color: var(--white);
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    text-transform: uppercase;
    padding: 6px 16px;
}

.buttons {
    gap: 12px;
}

.button {
    text-transform: none;
    font-weight: 600;
    white-space: nowrap;
}

.autoSave {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 8px;
    cursor: pointer;
}

.autoSaveText {
    white-space: nowrap;
    user-select: none;
    font-weight: 600;
    color: var(--text-color-primary);
}

.infoContainer {
    gap: 8px;
}

.verticalDivider {
    width: 1px;
    height: 32px;
    background-color: var(--grey-200);
}

.changesRequested,
.approved,
.reviewRequested {
    white-space: nowrap;
    user-select: none;
    font-weight: 600;
    font-size: 14px;
    gap: 4px;
}

.reviewRequested {
    color: var(--color-primary);
}

.changesRequested {
    color: var(--warning-color);
}

.approved {
    color: var(--greenish-blue);
}

.autoSaveActive {
    opacity: 1;
}

.autoSaveDisabled {
    opacity: 0.3;
}

.autoSave:hover {
    opacity: 0.7;
}

.requestChangesButton:global(.MuiButtonBase-root),
.editButton:global(.MuiButtonBase-root) {
    background-color: var(--disable-surface);
    color: var(--text-color-primary);
}

.approveButton:global(.MuiButtonBase-root) {
    background-color: var(--greenish-blue);
}

.requestChangesIcon {
    fill: var(--white);
}

.removeContainer {
    gap: 8px;
}
