:root {
    --color-primary: #122945;
    --color-secondary: #836d2d;
    --color-divider: #f4f4ff;
    --bg-color-light: #fff;
    --bg-color-light-secondary: #f4f4f4;
    --text-color-primary: #122945;
    --text-color-secondary: #7b8694;
    --button-text-color-secondary: #536478;
    --primary-button-hover: #18375e;

    --border-color: #122945;
    /* reaviz styles */
    --color-background: transparent;
    --color-on-background: #122945;
    --color-tooltip: #122945;
    --color-on-tooltip: #fff;
    --color-handle-fill: #2c343a;
    --color-handle-stroke: #67c2e4;
    --color-handle-drag-fill: transparent;
    --color-handle-dots: #67c2e4;
    --color-handle-line: #67c2e4;
    --table-text-color-secondary: #525f72;

    --blue-50: #a7b0bb;
    --blue-100: #9ba5b1;
    --light-blue-50: #f0f5ff;
    --light-blue-100: #e6ecef;
    --dark-blue-100: #3f4a54;
    --dark-blue-800: #122945;
    --secondary: #f75151;
    --white: #ffffff;
    --grey-100: #f9f9fa;
    --grey-200: #94969a;
    --grey-250: #757575;
    --text-grey-100: #b8bfc7;
    --grey-300: #e9edf2;
    --grey-background-mask: rgba(125, 125, 125, 0.3);
    --grey-350: #e1e4e8;
    --black-800: #1d1b20;
    --black: #000000;
    --greenish-blue: #3bbc97;

    --delete-button-hover-background: rgba(247, 81, 81, 0.68);
    --background-surface-low: #f5f7fa;
    --button-gray: #e7eaec;
    --secondary-surface: #e0e8f1;
    --disable-surface: #f7f9fa;
    --warning-color: #ed6c02;
    --status-cancel: #e50b0b;

    --header-height: 64px;
}

.react-grid-item.react-grid-placeholder {
    background-color: #122945 !important;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

@font-face {
    font-family: 'Inter';
    font-weight: 100;
    src: local('Inter'), url(/src/static/fonts/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 200;
    src: local('Inter'), url(/src/static/fonts/Inter-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 300;
    src: local('Inter'), url(/src/static/fonts/Inter-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: local('Inter'), url(/src/static/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: local('Inter'), url(/src/static/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: local('Inter'), url(/src/static/fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: local('Inter'), url(/src/static/fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: local('Inter'), url(/src/static/fonts/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: local('Inter'), url(/src/static/fonts/Inter-Black.ttf) format('truetype');
}

html,
body {
    margin: 0px;
    min-height: 100vh;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background: var(--white);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.color-secondary {
    color: var(--text-color-secondary);
}

#app-logo {
    width: auto;
    height: 40px;
}

#footer-logo {
    opacity: 0.7;
    height: 48px;
    width: auto;
}

.divider {
    border: 1px solid var(--color-divider);
    margin-bottom: 24px;
}

.date-select {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.date-picker {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    padding: 0 10px;
    height: 35px;
    font-size: 0.8rem;
    line-height: 1;
    color: var(--color-primary);
    background-color: var(--bg-color-light-secondary);
}
