.svgLegendContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}

.svgLegendContent {
    background-color: var(--white);
    width: fit-content;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 8px 16px;
    grid-column-gap: 16px;
}
