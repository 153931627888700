.container {
    gap: 34px;
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.textFieldContainer {
    position: relative;
    gap: 6px;
}

.textField:global(.MuiFormControl-root .MuiInputBase-input) {
    font-size: 12px;
    font-weight: 400;
}

.commentObj {
    gap: 4px;
}

.avatarContainer {
    gap: 8px;
}

.avatar {
    font-size: 14px;
}

.addCommentButton {
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
    align-self: flex-start;
}

.commentsBlock {
    margin-top: 8px;
    gap: 16px;
}

.authorName {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-color-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.commentId {
    font-size: 12px;
    font-weight: 600;
    color: var(--text-color-secondary);
}

.message {
    word-wrap: break-word;
    font-size: 12px;
    color: var(--black);
}

.showMoreButton {
    text-transform: none;
    align-self: flex-start;
    padding: 0;
}

.showMoreButton:hover {
    background-color: unset;
}
