.wrapper {
    gap: 20px;
}

.headerWrapper {
    gap: 16px;
}

.headerContainer {
    gap: 20px;
    position: relative;
}

.headerContainerInner {
    width: 100%;
    gap: 20px;
}

.formBlock {
    gap: 20px;
    flex-wrap: wrap;
}

.notesWrapper {
    width: 100%;
}

.notesFormItem {
    width: 100%;
}

.noText {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: var(--color-primary);
    text-align: center;
}

.absoluteDeleteButton {
    position: absolute;
    top: 0;
    right: 0;
}
