.parentContainer {
    position: fixed;
    bottom: 16px;
    left: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    max-height: calc(100vh - 16px); /* 16px -> bottom */
}

.parentContainer {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.parentContainer::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

div.customSnackbar {
    position: relative;
    bottom: unset;
    left: unset;
}
