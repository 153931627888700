.trigger {
    width: 100%;
}

.triggerLabel {
    cursor: pointer;
}

.bailEvents {
    width: 100%;
}

.bailEventsDropdown {
    width: 100%;
}

.titleContainer {
    width: 100%;
}
