.dashboardContainer {
    position: relative;
}

.createDashboardText {
    text-align: center;
    margin-top: 64px;
}

.autoSaving {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1;
}
