.list {
    overflow-x: auto;
    overflow-y: visible;
    gap: 12px;
}

.listItem {
    width: 0;
    flex-wrap: nowrap;
    gap: 8px;
}
