.filterContainer {
    display: flex;
    gap: 10px;
    padding: 12px 8px;
    border-bottom: 1px solid var(--text-grey-100);
}

.filterContainer > div {
    min-width: 160px;
    max-width: 160px;
}

button.deleteButton {
    height: fit-content;
}
