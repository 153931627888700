.mobileHeader {
    display: block;
}

.desktopHeader {
    display: none;
}

.menuIcon {
    fill: #ffffff;
}

.drawerContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 16px 0;
}

@media (min-width: 850px) {
    .mobileHeader {
        display: none;
    }

    .desktopHeader {
        display: block;
    }
}
