.container {
    display: flex;
}

.tabs {
    width: 100%;
}

.tabs:global(.MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator) {
    display: none;
}

.tabWrapper {
    flex: 0.2;
    max-width: 250px;
    border-radius: 8px 8px 0 0;
}

.tabWrapperActive {
    background: var(--grey-background-mask);
}

.draggableTab {
    background: var(--text-color-secondary);
}

.tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.tabTitleBlock {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: hidden;
}

.closeIconContainer {
    display: flex;
    align-items: center;
    padding-left: 8px;
    z-index: 1;
}

.closeIcon {
    transition: all 0.3s;
}

.closeIcon:hover {
    background: #1229453b;
    border-radius: 50%;
}

.tabTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: none;
}

.submitButton {
    height: 48px;
}

.savedStatus {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-primary);
    flex: none;
}

.tabLoadingIcon {
    flex: none;
}

.closeDashboardTitleContainer {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 8px;
}

.closeDashboardTitle {
    font-weight: 300;
    color: var(--text-color-primary);
}
