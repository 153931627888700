.autocomplete {
    align-items: center;
    max-width: 360px;
    width: 100%;
}

div.autocomplete:global(.MuiAutocomplete-root .MuiAutocomplete-input) {
    padding: 0 !important;
}

.chip {
    height: 24px;
    margin: 0;
}

.row {
    display: block !important; /* To shadow mui styles */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
