.sortIcon {
    fill: var(--blue-100);
}

.asc {
    transform: rotateX(180deg);
}

.desc {
    transform: rotateX(0deg);
}
