.toggleButton {
    line-height: 1;
}

.autocomplete {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-top: 4px;
    align-items: center;
    padding-left: 8px;
}

.autocomplete:global(.MuiStack-root .MuiTypography-root) {
    line-height: 2.4;
}

.autocomplete:global(.MuiStack-root .MuiButtonBase-root) {
    padding: 0;
    padding-right: 8px;
}
