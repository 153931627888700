.backContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: fit-content;
    border: none;
    background: none;
    outline: none;
    padding: 0;
}

.backTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 16px;
}
