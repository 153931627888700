.toolbarExportButton {
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 6px 16px;
    font-size: 1rem;
    color: var(--color-primary);
    text-transform: none;
}

.exportableTable {
    display: none;
}
