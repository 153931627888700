/* FormRow */
.formRow {
    position: relative;
    width: 100%;
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.nonAdaptiveFormRow {
    position: relative;
    width: 100%;
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}

.formRowAction {
    position: sticky;
    top: 0;
    right: 0;
    width: 36px;
    height: 100%;
    padding-top: 18px;
}

@media (min-width: 768px) {
    .formRow {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 992px) {
    .formRow {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (min-width: 1200px) {
    .formRow {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
