.container {
    max-width: 1200px;
    margin: 0 auto;
}

.accountContentWrapper {
    margin-bottom: 150px;
}

.titleWrapper {
    overflow: hidden;
    max-height: 100vh;
    transition: all 0.5s;
    height: auto;
    padding-top: 38px;
}

.title {
    color: #144a68;
}

.titleWrapperClose {
    max-height: 0;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.titleInnerContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.tabsWrapper {
    position: relative;
    border-bottom: 1px;
}

.searchContainer {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 16px;
    background: var(--grey-300);
    display: flex;
    gap: 10px;
    border-radius: 4px;
}

.searchContainer:before,
.searchContainer:hover:before,
.searchContainer:active:before,
.searchContainer:focus:before,
.searchContainer:after,
.searchContainer:hover:after,
.searchContainer:active:after,
.searchContainer:focus:after {
    border-bottom: none !important;
}

.searchContainer:global(.MuiInputBase-root.MuiInputBase-input:after),
.searchContainer:global(.MuiInputBase-root.MuiInputBase-input:before),
.searchContainer:global(.MuiInputBase-root.MuiInputBase-input:before):hover,
.searchContainer:global(.MuiInputBase-root.MuiInputBase-input:after):hover {
    border: none;
}

.deleteUserBtn svg,
.deleteGroupBtn svg {
    fill: var(--secondary);
}

.editPermissions,
.disabledEditPermissions {
    text-transform: unset;
    padding: 7px 20px;
    border-radius: 4px;
}

.editPermissions {
    background: var(--text-color-primary);
    color: white;
}
