.toolbarWrapper {
    gap: 24px;
}

.toolbarIcon {
    width: 20px;
    height: 20px;
    fill: var(--color-primary);
}

.lineWeightIcon {
    transform: rotateZ(180deg);
}
