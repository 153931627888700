.dashboardButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.dashboardButton:global(.MuiButtonBase-root .MuiButton-startIcon) {
    margin-right: 4px;
    margin-left: 0;
}

.dashboardChart {
    z-index: 2;
}
