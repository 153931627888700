.accordionSummary:global(.MuiAccordionSummary-root) {
    min-height: 0;
    padding: 0;
}

.accordionSummary:global(.MuiAccordionSummary-root .MuiAccordionSummary-content) {
    margin: 0;
}

.accordionDetails {
    padding: 8px 0px;
}
