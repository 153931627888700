.wrapper {
    gap: 20px;
}

.noText {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: var(--color-primary);
    text-align: center;
}
