main {
    height: calc(100vh - 64px);
    width: 100%;
    /* margin-bottom: -92px; */
    background: var(--white);
}

/* main:after {
    content: "";
    display: block;
    height: 92px;
} */
