.filterBlock {
    border-bottom: 1px solid var(--color-divider);
    padding: 6px;
    border-radius: 4px;
    position: relative;
}

.filterInnerBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterInnerBlockDisabled {
    filter: blur(1px);
}

.filterBlock:hover {
    background: var(--bg-color-light-secondary);
}

.filterBlock:last-child {
    border-bottom: none;
}

.filterLabelBlock {
    display: flex;
    gap: 8px;
    align-items: center;
}

button.closeIcon {
    position: absolute;
    right: 8px;
    top: 8px;
}

button.createButton:global(.MuiButtonBase-root) {
    background-color: var(--secondary);
}

.currentFilter {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

p.currentFilterText {
    font-weight: 700;
}
